<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <span
          class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
          ><img
            src="@/assets/RiskManagement/ExamplePicOCA-min.jpg"
            width="600"
            height="440"
            alt=""
            title="ExamplePicOCA"
            class="img-responsive wp-image-12086"
        /></span>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Offsite Consequence Analysis" size="large" />

        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS) regularly completes Offsite
            Consequence Analysis (OCA) for potential chemical releases. We have
            worked together with our clients and government representatives.
            Analyses are completed in accordance with&nbsp;<a
              title="EPA Link to RMP"
              href="http://www.epa.gov/osweroe1/content/rmp/rmp_guidance.htm"
              >EPA guidelines</a
            >&nbsp;and industry best practice. SMS can assist you in completing
            an Offsite Consequence Analysis including dispersion calculations
            and assistance in working with government agencies. If your facility
            stores or uses a chemical on the EPA regulated chemicals list above
            its threshold quantity, please&nbsp;contact us for further
            information.
          </p>
          <p>
            The goal of an Offsite Consequence Analysis is to determine
            distances to toxic endpoints from a potential chemical release. This
            information is vital in aiding emergency crews and company officials
            when mounting a response to a release situation. Once the analysis
            is complete, it is made available to the public and local
            government.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Offsite Consequence Analysis",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has extensive experience in performed Offsite Consequence Analysis to determine the potential hazards of the release of an EPA regulated chemical."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
